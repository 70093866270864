@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

*,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', Sans;
  background-color: #333;
  color: white;
}

h1 {
  font-size: 3rem;
  padding: 2rem;
  margin: 0 auto;
  text-align: center;
}

.main-container {
  padding: 1rem;
}

.markdown {
  font-size: 1.25rem;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5 {
  margin-left: -2rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.markdown pre {
  background-color: #123;
  margin: 0.5rem;
  padding: 0.5rem;
  margin-left: 1rem;
}
